import * as React from "react";
import Layout from "../../components/layout";
import Modal from "../../components/Modal";
import Seo from "../../components/seo";
import PortableText from "react-portable-text";
import { graphql } from "gatsby";

import TextSemibold from "../../utils/text-semibold";
import TextMedium from "../../utils/text-medium";

export const query = graphql`
  query ImprintQuery {
    allSanitySiteSettings {
      nodes {
        _rawImpressum
      }
    }
  }
`;

const ImpressumPage = ({ data }) => {
  return (
    <Modal one scroll>
      <Seo title="Impressum" />
      <div className="paragraph">
        <PortableText
          content={data.allSanitySiteSettings.nodes[0]._rawImpressum}
          serializers={{
            semibold: TextSemibold,
            medium: TextMedium,
          }}
        />
      </div>
    </Modal>
  );
};

ImpressumPage.Layout = Layout;

export default ImpressumPage;
